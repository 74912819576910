import React from 'react';
import ReactGA from 'react-ga';

import Icon from "./Icon";
import "./ProfessionalExperience.css";
import "./PersonalProjects.css";

const projects = [
    {
        name: 'React/Redux Chess',
        description: `
        A simple chess webapp in which you play as both players.
        It supports all the different moves, including castling,
        en passant, and pawn promotion.  The game tells you if you
        are in check, checkmate, or stalemate, and the game state
        is remembered in between page refreshes using local storage.
        `,
        addLabel: 'Notes',
        addContent: `
        The app is rendered using React, with game state managed through
        Redux.  In the future I plan to write more automated test cases
        and build some kind of multiplayer functionality, either networked
        or via a computer (AI) player.
        `,
        source: 'https://github.com/EdRyan/chess-react',
        demo: 'https://edryan.co/demos/chess-react/'
    },
    {
        name: 'React Calculator',
        description: `
        A simple four-function calculator using React, with keyboard support 
        and some implicit operations similar to the Windows calculator.
        Press the buttons on the page to add, subtract, multiply, or divide 
        non-negative whole numbers (e.g. 15 + 20 =). You can also use your keyboard 
        to enter numbers, operators, compute (press equals or enter), or backspace to 
        undo the last number. Similar to the Windows calculator, if you press the 
        equals button multiple times in a row, the same operation with the second 
        operand will be repeated. If you press an operation without specifying the 
        second operand, the current total will be assumed to be the second operand.
        `,
        addLabel: 'Notes',
        addContent: `
        In the future I plan to support for entering decimal and negative operands,
        and possibly other scientific calculator functions.
        `,
        source: 'https://github.com/EdRyan/calculator-react',
        demo: 'https://edryan.co/demos/calculator-react/'
    }
];

class PersonalProjects extends React.Component {

    onDemoClick(project) {
        ReactGA.event({
            category: 'Projects',
            action: 'Opened Demo',
            label: project.name
        });
    }

    onSourceClick(project) {
        ReactGA.event({
            category: 'Projects',
            action: 'Viewed Source',
            label: project.name
        });
    }

    renderProjects() {
        return (
            <div>
                <div>
                    {projects.map(p => {
                        return <div key={p.name} className="card">
                            <div className="card__content">
                                <h4>{p.name}</h4>
                                <h5>Description</h5>
                                <p>{p.description}</p>
                                <h5>{p.addLabel}</h5>
                                <p>{p.addContent}</p>
                            </div>
                            <div className="card__actions">
                                <a className="card__action" href={p.demo} onClick={() => this.onDemoClick(p)} target="_blank"><Icon icon="gamepad"/> Demo</a>
                                <a className="card__action" href={p.source} onClick={() => this.onSourceClick(p)} target="_blank"><Icon icon="github"/> GitHub</a>
                            </div>
                        </div>
                    })}
                </div>
            </div>);
    }

    render() {
        return (
            <div className="container professional-experience">
                <h3>Personal Projects</h3>
                <p>
                    I started programming as a kid on an IBM PS2
                    using BASIC, back in the days before GitHub.
                    These days, I focus most of my time and attention
                    on the projects I am working on at work. However,
                    I do like to experiment with new technologies when
                    time permits. Here are a couple of projects that
                    I have open sourced, which I have used to practice
                    developing with React and Redux:
                </p>
                {this.renderProjects()}
            </div>
        )
    }
}

export default PersonalProjects;