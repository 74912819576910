import React from "react";
import ReactGA from "react-ga";

import Icon from "./Icon";

const ContactButton = () => {

    const onClick = () => {
        ReactGA.event({
            category: 'Contact',
            action: 'Pressed contact button',
            label: document.documentElement.scrollTop
        });
    };

    return (<a
        href="mailto:edwardjryanjr@gmail.com?subject=Hi from your website!"
        onClick={onClick}
        target="_blank"
        className="contact-button">
        <Icon icon="envelope"/> Contact
    </a>);
};

export default ContactButton;