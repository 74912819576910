import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGamepad, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(
    fab, faGamepad, faEnvelope
);

const Icon = ({ icon }) => {
    const faIcon = (() => {
        switch (icon) {
            case 'github':
                return ['fab', 'github'];
            case 'gamepad':
                return faGamepad;
            case 'envelope':
                return faEnvelope;
        }
    })();

    return <FontAwesomeIcon icon={faIcon} />
};

export default Icon;