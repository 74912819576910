import React from 'react';

import "./ProfessionalExperience.css";

const projects = [
    {
        name: 'Space Management System',
        description: `
        This system reads AutoCAD floorplans to catalog the buildings,
        floors, rooms, and other spaces in over 13 million square feet
        of space in New York University's portfolio. It
        stores and reports on associated metadata, e.g. space types
        (classrooms, offices, laboratories, etc.), department(s)
        assigned to the space, people occupying the space, as well as
        violations received from government agencies in those spaces.
        The tools in this system allow the University to get money back
        from the government for certain types of research grants, and
        simplifies the annual budgeting process by allocating facilities
        charges to departments based on their space usage and other
        customizable rules.
        `,
        addLabel: 'Engineering',
        addContent: `
        The data is stored in a bitemporal MS SQL database (with one
        axis of time to represent how we think the University looked
        in the past and will look in the future; another axis to provide
        a fully auditable record of changes to this timeline, so that
        you can see how planned and actual space usage evolved over
        time and who made those changes).  The application was built
        with ASP.NET MVC using C#.  I architected this project and was on the
        design and development teams as well.
        `
    },
    {
        name: 'Capital Project Management System',
        description: `
        This is a project management and oversight tool for capital
        projects, with an emphasis on construction projects.  It is
        used to manage over $1 Billion (?) in capital improvements.
        It provides forms, workflow, and reporting covering the entire
        lifecycle of the project, from project initiation to funding
        requests/modifications, bidding, purchase orders and invoicing,
        and project closeout, among other functionality.  It enforced
        proper oversight and accountability by routing documents to
        different users for approval based on the financial authority
        they had been granted in the institution.
        `,
        addLabel: 'Engineering',
        addContent: `
        This system integrates with Oracle PeopleSoft to retrieve
        and update the financial information for capital projects.
        One interesting challenge for this project was when we were
        asked to help the treasury department better predict cash flows
        for capital expenses. To help solve this, I deeply investigated
        existing financial data and reviewed published research on the
        topic to come up with an approximation curve for project spending
        that the system would generate on a recurring basis. The application
        was built using PHP. I was heavily involved in the design,
        architecture, and development of this tool.
        `
    },
    {
        name: 'Hotel Operations System',
        description: `
        This system is used in hundreds of luxury independent and
        branded hotels around the world to manage their operations,
        dispatch guest requests and internal tasks to various users
        in different departments, and keep track of items such as
        packages and lost and found.  This tool increases communication
        between staff and allows for faster completion of requests,
        which encourages guests to come back to the hotel and allows
        the hotel to be eligible for higher ratings from agencies such
        as Forbes, leading to higher revenue.
        `,
        addLabel: 'My Role',
        addContent: `
        As product manager, I was responsible for delivering solutions
        to user problems and to help the company meet its revenue targets
        by delivering functionality needed to meet the requirements of
        key stakeholders. As part of this, I launched a new business
        line for the company and designed new features for the company's
        largest enterprise customers. Because I was working with a
        distributed team of developers under intense time pressures, I
        needed to write very detailed specifications to cover exactly how
        each part of the functionality would work, exercising my engineering
        skills. Some interesting challenges I worked on were allowing users
        to schedule recurring tasks, deeply customize roles and permissions,
        book restaurant reservations via the OpenTable API, and track
        arbitrary lists of items (e.g. packages) with custom fields and
        workflow.
        `
    }
];

class ProfessionalExperience extends React.Component {

    renderProjects() {
        return (
            <div>
                <div>
                    {projects.map(p => {
                        return <div key={p.name} className="card">
                            <div className="card__content">
                                <h4>{p.name}</h4>
                                <h5>Description</h5>
                                <p>{p.description}</p>
                                <h5>{p.addLabel}</h5>
                                <p>{p.addContent}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>);
    }

    render() {
        return (
            <div className="container professional-experience">
                <h3>Professional Experience</h3>
                <p>
                    I have been building enterprise software for over nine
                    years, with six of those years as a developer/architect
                    and three of those years as a product manager.  I have
                    worked on a variety of domains, delivering six systems
                    from scratch and designing various improvements on top
                    of that. Here are some highlights from my career:
                </p>
                {this.renderProjects()}
            </div>
        )
    }
}

export default ProfessionalExperience;