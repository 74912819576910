import React from 'react';
import ReactGA from 'react-ga';

import Header from './Header';
import ProfessionalExperience from './ProfessionalExperience';
import PersonalProjects from './PersonalProjects';
import ContactButton from './ContactButton';
import './App.css';

ReactGA.initialize('UA-135301402-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {

    render() {
        return (
            <div>
                <Header />
                <ProfessionalExperience />
                <PersonalProjects />
                <ContactButton />
            </div>
        );
    }
}

export default App;