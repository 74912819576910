import React, {Component} from 'react';

import './Header.css';

class Header extends Component {
    render() {
        return (
            <header className="header">
                <h1 className="heading-primary">
                    <span className="heading-primary--main">Ed Ryan</span>
                    <span className="heading-primary--sub">Full Stack Engineer</span>
                </h1>
                <div className="header__blurb">
                    <ul className="header__blurb-list">
                        <li>Based in New York</li>
                        <li>User-focused</li>
                        <li>Experienced in Product Management</li>
                        <li><span>Now seeking new opportunities</span><span className="label">NEW</span></li>
                    </ul>
                </div>
                <h2 className="heading-secondary">
                    <ul className="heading-secondary__list">
                        <li>C#</li>
                        <li>CSS</li>
                        <li>HTML</li>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>Redux</li>
                        <li>SQL</li>
                    </ul>
                </h2>
            </header>
        );
    }
}

export default Header;